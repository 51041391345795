<template>
  <vs-popup title="Files" class="sm:popup-w-60" :active="isActive" v-on:update:active="emitModalIsActive">
    <div class="w-full h-screen-50 overflow-y-scroll">
      <table class="w-full border-collapse table-auto">
        <tbody class="text-sm">
          <tr v-for="(item, index) in filesUrl" :key="index" class="hover-bg-primary-transparent-10 cursor-pointer" @click="onItemClicked(item)">
            <td class="px-2 py-2 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap w-10">{{ index + 1 }}</td>
            <td class="px-2 py-2 border border-solid d-theme-border-grey-light text-left">
              <div class="flex items-center flex-wrap space-x-2">
                <feather-icon :icon="getFileIcon(item)" svgClasses="h-5 w-5"/>
                <span class="">{{ getFileName(item) }}</span>
              </div>
            </td>
            <td class="px-2 py-2 border border-solid d-theme-border-grey-light text-left whitespace-no-wrap w-20 uppercase">{{ getFileExtension(item) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <ImageViewer ref="viewer"/>
  </vs-popup>
</template>

<script>
import _ from 'lodash'
import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'

export default {
  name: 'FilesViewer',
  props: {
    isActive: { required: true, type: Boolean },
    filesUrl: { required: true, type: Array }
  },
  components: {
    ImageViewer: () => import('@/views/components/image-viewer/ImageViewer')
  },
  data () {
    return {
      extensions: {
        image: ['jpg', 'jpeg', 'png', 'gif'],
        video: ['mp4', 'avi', 'mkv', 'webm'],
        document: ['pdf', 'docx', 'doc', 'xlsx', 'xls', 'txt']
      }
    }
  },
  methods: {
    onItemClicked (url) {
      const extension = this.getFileExtension(url)
      const isImage = _.find(this.extensions.image, item => item === extension)
      const isVideo = _.find(this.extensions.video, item => item === extension)

      if (isImage) {
        this.showImage(url)
      } else if (isVideo) {
        this.showVideo(url)
      } else {
        window.open(url)
      }
    },

    getFileIcon (url) {
      const extension = this.getFileExtension(url)
      switch (extension) {
        case _.find(this.extensions.image, item => item === extension):
          return 'ImageIcon'
        case _.find(this.extensions.video, item => item === extension):
          return 'FilmIcon'
        case _.find(this.extensions.document, item => item === extension):
          return 'FileTextIcon'
        default:
          return 'FileIcon'
      }
    },

    getFileName (url) {
      return /[^/]*$/.exec(url)[0]
    },

    getFileExtension (url) {
      return (/[^.]([^.]*)$/.exec(url)[0]).toLowerCase()
    },

    showImage (url) {
      const items = [url]
      this.$refs.viewer.show(items)
    },

    showVideo (url) {
      const lightbox = GLightbox()
      const elements = _.map([url], item => ({ href: item }))
      lightbox.setElements(elements)
      lightbox.open()
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
