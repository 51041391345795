<template>
  <div class="mt-4">
    <div class="flex items-center space-x-3">
      <div class="w-40 h-40 p-0 m-0">
        <vs-avatar icon-pack="feather" icon="icon-bookmark" size="40px"/>
      </div>
      <div class="w-full">
        <h4 class="font-semibold">{{ initData.pekerjaan.nama_item_progress_pekerjaan }}</h4>
        <p class="mt-1 opacity-75">Bobot {{ initData.pekerjaan.bobot }}%</p>
      </div>
    </div>

    <vs-divider>Progress History</vs-divider>

    <div>
      <!--table-->
      <vs-table :data="initData.progress" class="fix-table-input-background">
        <template slot="thead">
          <vs-th class="whitespace-no-wrap">#</vs-th>
          <vs-th class="whitespace-no-wrap">Progress</vs-th>
          <vs-th class="whitespace-no-wrap">Pengerjaan</vs-th>
          <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap">Gambar</vs-th>
          <vs-th class="whitespace-no-wrap">File</vs-th>
          <vs-th class="whitespace-no-wrap">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap">Progress Approval</vs-th>
          <vs-th class="whitespace-no-wrap">Gambar Approval</vs-th>
          <vs-th class="whitespace-no-wrap">File Approval</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(item, index) in data" :key="index" class="text-sm" :class="{'bg-warning text-black font-semibold': currentProgress.id === item.id}">
            <vs-td class="whitespace-no-wrap">{{ index + 1 }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.progress }}%</vs-td>
            <vs-td class="">{{ item.pekerjaan }}</vs-td>
            <vs-td class="">{{ item.keterangan_kontraktor }}</vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="text-sm underline cursor-pointer" v-if="item.foto_kontraktor_url_list.length > 0" @click="showImages(item.foto_kontraktor_url_list)">Lihat</span>
              <span v-else>-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="text-sm underline cursor-pointer" v-if="item.file_kontraktor_url_list.length > 0" @click="showFiles(item.file_kontraktor_url_list)">Lihat</span>
              <span v-else>-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap"><span class="text-sm underline cursor-pointer" @click="showModalApproval(item)">Lihat</span></vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="text-sm underline cursor-pointer" v-if="item.foto_teknik_url_list.length > 0" @click="showImages(item.foto_teknik_url_list)">Lihat</span>
              <span v-else>-</span>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <span class="text-sm underline cursor-pointer" v-if="item.file_teknik_url_list.length > 0" @click="showFiles(item.file_teknik_url_list)">Lihat</span>
              <span v-else>-</span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modals-->
    <ImageViewer ref="viewer"/>
    <FilesViewer :filesUrl="modalFiles.filesUrl" :isActive.sync="modalFiles.active"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
  </div>
</template>

<script>
import ImageViewer from '@/views/components/image-viewer/ImageViewer'
import FilesViewer from '@/views/components/files-viewer/FilesViewer'
import ModalApprovalProgress from '@/views/components/approval-progress/ModalApprovalProgress'
import _ from 'lodash'

export default {
  name: 'TabProgress',
  props: ['isActive', 'initData'],
  components: {
    ImageViewer,
    FilesViewer,
    ModalApprovalProgress
  },
  computed: {
    currentProgress () {
      const idProgressUnit = this.$route.params.idProgressUnit.toString()
      return _.find(this.initData.progress, item => item.id.toString() === idProgressUnit)
    }
  },
  data () {
    return {
      modalFiles: {
        filesUrl: [],
        active: false
      },
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      }
    }
  },
  methods: {
    showImages (images) {
      const items = _.cloneDeep(images)
      this.$refs.viewer.show(items)
    },

    showFiles (urls) {
      this.modalFiles.filesUrl = urls
      this.modalFiles.active = true
    },

    showModalApproval (item) {
      this.modalApproval.type = 'SPP-PROGRESS'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.spkProgressApproval.activeTab
      this.$store.commit('approvals/spkProgressApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.spkProgressApproval.activeTab
      this.$store.commit('approvals/spkProgressApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
